<template>
  <v-row class="inner-content">
    <v-col>

      <v-card class="pt-2">
        <v-card-text>
          <p>Use this feature to run the actual keyword relocations for the selected account after you have imported the labeling data and checked the pre-processing results.</p>
        </v-card-text>
      </v-card>

      <v-card class="pt-2">
        <v-card-title>
          <span>Account</span>

          <v-spacer></v-spacer>

          <GoogleAccountAutoComplete
            v-model="selectedAccountID"
            @changed="accountChanged"
          ></GoogleAccountAutoComplete>

          <v-spacer></v-spacer>

          <v-btn color="error" :loading="processing" @click="process">Make changes</v-btn>
          <v-spacer></v-spacer>

        </v-card-title>

        <SystemJobsTable :headers="jobHeaders" :jobType="jobType" :updateKey="updateTableKey"></SystemJobsTable>
      </v-card>

    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'KeywordRelocationMakeChanges',

  data () {
    return {
      lsSelectedAccount: 'keywordRelocation_selectedAccount',
      selectedAccountID: 0,
      processing: false,
      updateTableKey: 'init',
      jobType: 'Core - Keyword Relocation Processing',
      jobHeaders: [
        { text: 'User', value: 'user_name' },
        { text: 'Account', value: 'text1' },
        { text: 'Status', value: 'status' },
        { text: 'Entry at', value: 'entry_at_time' },
        { text: 'Completed at', value: 'completed_at_time' },
        { text: 'External message', value: 'external_message' },
      ],
    }
  },

  computed: {
    processEndpoint () {
      return '/a/google/accounts/' + this.selectedAccountID + '/process-keyword-relocations'
    },
  },

  components: {
    GoogleAccountAutoComplete: () => import('@/components/autocompletes/GoogleAccountAutoComplete.vue'),
    SystemJobsTable: () => import('@/components/tables/SystemJobsTable.vue')
  },

  methods: {
    accountChanged: function () {
      if (!this.selectedAccountID) {
        return
      }

      // save to local storage
      localStorage.setItem(this.lsSelectedAccount, JSON.stringify(this.selectedAccountID))
    },

    process: function () {
      if (!this.selectedAccountID) {
        return
      }
      this.processing = true

      var updateObJ = {
        'accountID': this.accountID
      }
      var body = JSON.stringify(updateObJ)

      this.$http.post(this.processEndpoint, body).then(resp => {
        this.updateTableKey = this.$dateTime.now().toString()
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'POST', url: this.processEndpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.processing = false)
    },
  },

  created: function () {

    // if present, use local selected account
    if (localStorage.getItem(this.lsSelectedAccount)) {
      this.selectedAccountID = JSON.parse(localStorage.getItem(this.lsSelectedAccount))
    }
  },
}
</script>
